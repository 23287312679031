import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import SubTileCard from "./SubTileCard";
import "./SubTiles.scss";

const SubTiles = ({module, pageId, imageTransforms}) => {
    const settings =  {
      dots: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      arrows: false,
      autoplay: false,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }    
    return (      
        <section className="sub-tile-section">
          <div className="sub-tile-wrap">
            <Container>  
             <Row className="sub-tile-heading-wrap">
                  <h2 className="heading">{module?.Module_Title}</h2>
                  <p>{module?.Module_Description}</p>
              </Row>
  
              <Row className="sub-tile-card-wrap">
                <Slider {...settings}>
                    {module?.Tile_Image_Items.map(item => (
                        <Col className="small-card" >
                            <SubTileCard TileCardData={item} pageId={pageId} imageTransforms={imageTransforms?.Image_Transforms}/>
                                
                        </Col>
                    ))}
                </Slider>
              </Row>
            </Container>
          </div>
        </section>
    )
  };
  export default SubTiles;  