import React, { useEffect } from 'react';
import "./Elfsight.scss";

const ElfsightWidget = ({ widgetID }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [widgetID]);

  return (
      <div className="elfsight-wrapper">
          <div className={`elfsight-app-${widgetID}`} data-elfsight-app-lazy></div>
      </div>
  );
};

export default ElfsightWidget;