import React from "react"
import GetGGFXImage from "../common/site/get-ggfx-image"
import parse from "html-react-parser"

const SubTileCard = props => {
  const tileCardItem = props.TileCardData;
  let processedImages = JSON.stringify({})
  if (props?.imageTransforms) {
    processedImages = props.imageTransforms
  }

  return (
    <div className="sub-tile-card">
      <div className="sub-tile-card__image img-zoom">
          <GetGGFXImage
            imagename={"articles.Image.tileimg"}
            imagesources={tileCardItem?.Image}
            fallbackalt={tileCardItem?.Title}
            imagetransformresult={processedImages}
            id={props?.pageId}
          />
      </div>
      <div className="sub-tile-card-info-wrap">
        <h2 className="sub-tile-card__title">
            {tileCardItem?.Title}
        </h2>
        <div className="sub-tile-card__description">
            {parse(tileCardItem?.Description)}
        </div>
      </div>
    </div>
  )
}

export default SubTileCard
