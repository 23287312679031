import React,{useEffect, useState} from "react";
import { graphql } from "gatsby"
import loadable from "@loadable/component";
import SEO from "../components/Seo/seo"
import BrandLogo from "../images/logo.svg";
import Header from "../components/Header/Header"
import Home from "../pages/Home/Home"
import "slick-carousel/slick/slick.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss"
import "../scss/grunticon.scss"
import { Helmet } from "react-helmet"
import { Container, Row, Col } from "react-bootstrap"
import Footer from "../components/Footer/Footer"
import Sticky from "../components/Sticky-Btn/Sticky"
import PropertyList from "../components/PropertyList/PropertyList"
import HomePropertyFeatures from "../components/PropertyFeatures/HomePropertyFeatures"
import RealEstate from "../components/RealEstate/Estate"
import Team from "../components/OurTeam/Team.js"
import Career from "../components/Careers/Careers"
import NewsDetails from "../components/NewsDetails/NewsDetails"
import Help from "../components/Help/Help"
import Faq from "../components/FaqList/Faq"
import TwoColumns from "../components/TwoColumns/TwoColumns"
import SubTiles from "../components/SubTiles/SubTiles"

// const Footer = loadable(() => import("../components/Footer/Footer"));
// const Sticky = loadable(() => import("../components/Sticky-Btn/Sticky"));
// const PropertyList = loadable(() => import("../components/PropertyList/PropertyList"));
// const HomePropertyFeatures = loadable(() => import("../components/PropertyFeatures/HomePropertyFeatures"));
// const RealEstate = loadable(() => import("../components/RealEstate/Estate"));
// const Team = loadable(() => import("../components/OurTeam/Team.js"));
// const NewsDetails = loadable(() => import("../components/NewsDetails/NewsDetails"));
// const Career = loadable(() => import("../components/Careers/Careers"));
// const Help = loadable(() => import("../components/Help/Help"));
// const Faq = loadable(() => import("../components/FaqList/Faq"));
// const TwoColumns = loadable(() => import("../components/TwoColumns/TwoColumns"));
// const SubTiles = loadable(() => import("../components/SubTiles/SubTiles"));

// markup
const HomeTemplate = props => {
  const GQLPage = props.data.glstrapi?.article
  const GQLModules = props.data.glstrapi?.article?.Modules
  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })

  }, [])
 // console.log('loaded fro home template - v5')

  return (
    <>
     <Helmet>
      {/* <script  src="../../prefetch.js"></script> */}
      </Helmet>
      <SEO
          title={GQLPage.Meta_Title ? GQLPage.Meta_Title : GQLPage.Title}
          description={GQLPage.Meta_Description}
          image = {GQLPage.Title === "Home" || GQLPage.Banner_Overlay_Bg?.url ==="" ?  BrandLogo :   GQLPage.Banner_Overlay_Bg?.url}
        />

      <div className={`page-wrapper ${GQLPage.Page_Class}`}>
        <Header layout={GQLPage.Layout} />     
          <Home
            title={GQLPage.Banner_Title}
            multi_banner_title={GQLPage.Multi_Banner_Title}
            search={GQLPage.Show_Search}
            reviews={GQLPage.Banner_Reviews}
            buttons={GQLPage.Banner_CTA}
            overlaybanner={GQLPage.Banner_Overlay_Bg}
            bannerimage={GQLPage.Banner_Image}
            imagetransforms={GQLPage.imagetransforms}
            layout={GQLPage.Layout}
            id={GQLPage.id}
            video={GQLPage.Banner_Video_Link}
            pageTitle={GQLPage.Title}
            content={GQLPage.Banner_Content}
            page={GQLPage}
          />
        {GQLModules.map((Modules, i) => {
          return (
            <>
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsThumbnailGrid"  &&(
                <>
                  <section className="property-list ">
                    <Container>
                      <Row>                       
                        <Col md={12} className="d-md-flex list-wrap ">
                          {Modules.Grid_List &&
                            Modules.Grid_List.map(property => (
                              <PropertyList
                                key={property.id}
                                propertyValue={property}
                              />
                            ))}
                        </Col>
                      </Row>
                    </Container>
                  </section>
                </>
              )}
              
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                GQLPage.Title == "Home" &&
                Modules.Select_Module === "Featured_Properties" && renderComponent && (
                  <HomePropertyFeatures />
                )}
              
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsFullWidthBlueBlock"  && (
                <RealEstate
                  heading={Modules.Block_Heading}
                  content={Modules.Block_Content}
                  image={Modules.Block_Image}
                  videolink={Modules.Block_Video_URL}
                  ctas={Modules.Block_CTA}
                />
              )}
               {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "Team_Slider" && <Team />}

             {Modules.__typename === "GLSTRAPI_ComponentComponentsFaq" && (
                <Faq faqs={Modules.FAQ_List} />
              )}

              {Modules.__typename === "GLSTRAPI_ComponentComponentsTwoColumnContent" && (
                <TwoColumns module={Modules} />
              )}

             {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsSubTiles" && (
                <SubTiles module={Modules} pageId={GQLPage.id} imageTransforms={GQLPage.imagetransforms} />
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsImageBlocks"  &&(
                <Career items={Modules.Image_Block_Items} />
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "News_Slider_Two_Column" && (
                  <NewsDetails />
                )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "Lets_Work_Together_Module"  &&(
                  <Help module={Modules.Select_Module} />
                )}
                
                {/* ----------------- */}             
            </>
          )
        })}
      
        <Footer popularsearch={GQLPage.Select_Popular_Search}  />
        <Sticky />

      </div>
    </>
  )

   
  }


export default HomeTemplate
export const pageQuery = graphql`
  query HomeQuery($articleId: ID!) {
    glstrapi {
      article(id: $articleId, publicationState: LIVE) {
        id
        Title
        Meta_Title
        Meta_Description
        Page_Class
        Layout
        Show_Search
        Banner_Reviews
        Banner_Title
        Multi_Banner_Title {
          Title
        }
        Banner_Content
        Select_Popular_Search
        Banner_Type
        imagetransforms
        Banner_Image {
          url
          alternativeText
        }
        Select_Contact {
          Designation
          Email
          URL
          id
          Phone
          Name
          imagetransforms
          Image {
            url
            url_sharp {
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  width: 180
                  quality: 80
                  layout: FIXED
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                )
              }
            }
          }
          languages
        }
        Banner_Overlay_Bg {
          url
          alternativeText
        }
        Banner_Video_Link
        Banner_CTA {
          CTA_Label
          CTA_Link {
            Primary_URL
          }
          CTA_Secondary_Link
        }

        Modules {
          ... on GLSTRAPI_ComponentComponentsThumbnailGrid {
            id
            __typename
            Thumbnail_Grid_Heading
            Grid_List {
              id
              Grid_Thumbail_Text
              Grid_Thumbail_Title
              Grid_Thumbail_Link_Label
              Grid_Thumbail_Link {
                Primary_URL
              }
              Grid_Thumbail_Image {
                alternativeText
                url
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      width: 180
                      quality: 80
                      layout: FIXED
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
              Grid_Thumbail_Custom_Link
            }
          }
          ... on GLSTRAPI_ComponentComponentsGlobalModules {
            id
            __typename
            Select_Module
          }
          ... on GLSTRAPI_ComponentComponentsFaq {
            id
            __typename
            FAQ_List {
              Content
              Title
            }
          }
          ... on GLSTRAPI_ComponentComponentsTwoColumnContent {
            __typename
            id
            Left_Content
            Right_Content
            Select_Staffs {
              Designation
              URL
              Name
              Phone
              imagetransforms
              Image {
                url
                alternativeText
              }
              id
            }
            Team_Column_Heading
          }
          ... on GLSTRAPI_ComponentComponentsSubTiles {
            __typename
            id
            Module_Description
            Module_Title
            Tile_Image_Items {
              Description
              Title
              id
              Image {
                url
                alternativeText
              }
            }            
          }
          ... on GLSTRAPI_ComponentComponentsFullWidthBlueBlock {
            id
            __typename
            Block_Video_URL
            Block_Heading
            Block_Content
            Block_Image {
              alternativeText
              url
              url_sharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 560
                    height: 540
                    quality: 80
                    layout: FIXED
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
            }
            Block_CTA {
              CTA_Secondary_Link
              CTA_Label
              CTA_Link {
                Primary_URL
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsImageBlocks {
            id
            __typename
            Image_Block_Items {
              id
              Image_Block_Title
              Image_Block_CTA_Label
              Image_Block_Tile_Image {
                alternativeText
                url
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      width: 600
                      quality: 100
                      layout: FIXED
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
              Image_Block_CTA {
                Primary_URL
              }
            }
          }
        }
      }
    }
  }
`
