import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Dropdown } from 'react-bootstrap';
// import { BackParentMenu } from "../../../queries/common_use_query";
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// markup
const SiteBreadcrumbs = (props) => {

  const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        allMenus(sort:"Sorting:asc") {
          id
          Label
          Primary_URL
          Secondary_URL
          Show_In_Top_Nav
          Main_Parent {
            id
            Label
            Primary_URL
            Secondary_URL
          }
          Sub_Parent {
            id
            Label
            Primary_URL
            Secondary_URL
          }
        }
      }
    }
  `)
  var breadCrumbList = [];
  var breadCrumbSchema = [{
    "@type": "ListItem",
    "position": 1,
    "item":
    {
      "@id": `${process.env.GATSBY_SITE_URL}`,
      "name": `Home` 
    }
  }];
  var menu_data = data.glstrapi.allMenus;
  if (typeof window !== "undefined") {
    var pageURL = window.location.href.split("#")[0] ? window.location.href.split("#")[0] : '';
    var removeSlash = pageURL.replace(/^(.+?)\/*?$/, "$1");
    var currenturl = props.alias ? props.alias : removeSlash.substr(removeSlash.lastIndexOf('/') + 1);
    currenturl = currenturl.replace(/\?.+/, '')
    if (currenturl === "send-to-friend" || currenturl === "book-a-viewing") {
      currenturl = ""
    }
  }

  menu_data && menu_data.length > 0 && menu_data.map((menu, index) => {
    return <>
      {menu.Main_Parent === null && menu.Sub_Parent === null && menu.Primary_URL === currenturl &&
        breadCrumbList.push({ className: "back-btn", label: menu.Label, url: null, icon: null })
      }
      {menu.Main_Parent && menu.Sub_Parent === null && menu.Primary_URL === currenturl &&
        breadCrumbList.push({ className: "back-btn 1", label: menu.Main_Parent.Label, url: `/${menu.Main_Parent.Primary_URL}/`, icon: null })
      }
      {menu.Main_Parent && menu.Sub_Parent && menu.Primary_URL === currenturl &&
        breadCrumbList.push({ className: "back-btn 4", label: menu.Sub_Parent.Label, url: `/${menu.Main_Parent.Primary_URL}/${menu.Sub_Parent.Primary_URL}/`, icon: null })
      }
      {menu.Main_Parent != null && !props.itemlabel && !props.menulabel && menu.Primary_URL === currenturl &&
        breadCrumbList.push({ className: "back-btn 5 ", label: menu.Label, url: null, icon: null })
      }
      {menu.Main_Parent != null && !props.itemlabel && props.menulabel && menu.Primary_URL === currenturl &&
        breadCrumbList.push({ className: "back-btn 6", label: menu.Label, url: `/${menu.Main_Parent.Primary_URL}/${menu.Primary_URL}/`, icon: "icon-arrow-right" })
      }
    </>
  })

  {
    props.baselabel && props.baseurl &&
      breadCrumbList.push({ className: "back-btn", label: props.baselabel, url: props.baseurl, icon: null })
  }
  {
    props.itemlabel &&
      breadCrumbList.push({ className: "back-btn", label: props.itemlabel, url: props.property_url ? props.property_url : "", icon: null })
  }
  {
    props.menulabel &&
      breadCrumbList.push({ className: "back-btn", label: props.menulabel, url: null, icon: null })
  }
  let pos = 1;
  breadCrumbList.map((item, i) => {
    let url = `${process.env.GATSBY_SITE_URL}${item.url}`
    if (i + 1 == breadCrumbList.length && breadCrumbList.length > 1 && currenturl) {
      url = `${process.env.GATSBY_SITE_URL}${breadCrumbList[i-1].url}${currenturl}/`
    }else if(i + 1 == breadCrumbList.length && breadCrumbList.length == 1){
      url = `${process.env.GATSBY_SITE_URL}/${currenturl}/`
    }

    pos++;
    breadCrumbSchema.push({
      "@type": "ListItem",
      "position": pos,
      "item":
      {
        "@id": `${url}`,
        "name": `${item.label}`, 
      }
    }) 
  })
  //console.log('breadCrumbList', breadCrumbList, currenturl, props.baseurl, currenturl)
  return (
    <React.Fragment>
      {breadCrumbList && breadCrumbList.length > 0 &&
           <Helmet>
           <script type="application/ld+json">{`{
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": ${JSON.stringify(breadCrumbSchema, null, 2)}
           }`}
           </script>
         </Helmet>
      }
 
      <div className={`back-block breadcrumbs ${props.style}`}>
        <div className="back-wrap">
          <nav aria-label="breadcrumb" className="breadcrumb-wrapper">
            <ol class="breadcrumb" >
              <li class="breadcrumb-item"><Link to="/" className="back-btn">Home</Link></li>
              {breadCrumbList && breadCrumbList.length > 0 && breadCrumbList.map((menu, index) => {
                return <>
                  {menu.label && menu.url === null &&
                    <li class="breadcrumb-item"><Link className={menu.className} >{menu.label}
                      {menu.icon && <i className={menu.icon}></i>}
                    </Link></li>
                  }
                  {menu.label && menu.url != null &&
                    <li class="breadcrumb-item"><Link className={menu.className} to={menu.url}>{menu.label}
                      {menu.icon && <i className={menu.icon}></i>}
                    </Link></li>
                  }
                </>
              })}

            </ol>
          </nav>
        </div>

      </div>
    </React.Fragment>
  )
}
export default SiteBreadcrumbs
