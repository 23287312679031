import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PlayVideo from "../PlayVideo/PlayVideo";

import "./Estate.scss";
import parse from 'html-react-parser';
import GenerateLink from "../common/site/GetLink"
// import {  getImage } from "gatsby-plugin-image"
import GetGatsbyImage from "../common/site/get-gatsby-image";
// import { AnimationOnScroll } from "react-animation-on-scroll";
const Customer = (props) => {
  const [isPlay, setPlay] = useState(false); 
  // const image = getImage(props.image.url_sharp)
  return (
    <React.Fragment>
      <section className="estate-bluebg">
        <Container>
          <Row>
            <Col md={12} lg={6} className="get-zoom order-lg-2">
              
                <div className="estate-img ">
                  <picture className="img-zoom">
                    <GetGatsbyImage image={props.image} fallbackalt={props.heading} />
                  </picture>
                  {props.videolink && props.videolink.length > 0 && 
                  <button className="video-wrap" onClick={(e) => { setPlay(true)}}>
                    <i className="video-btn"></i>
                  </button>}
                </div>
              
            </Col>
            <Col md={12} lg={6} className="estate-img-wrap">
              
                <div className="estate-info">
                  <h2 className="sm-title">{props.heading}</h2>
                  {parse(props.content)}
                 
                  {props.ctas &&
                    <div className="btn-wrap">
                    {props.ctas.map((item, index) => (
                    <GenerateLink secondarylink={item.CTA_Secondary_Link} link={item.CTA_Link?.Primary_URL} class={index === 0 ?"btn":"btn btn-secondary"}>
                      <span>{item.CTA_Label}</span>
                      <i className="icon-right"></i>
                    </GenerateLink>
                    ))}
                    </div>
                  }
                  
                </div>
              
            </Col>
          </Row>
        </Container>
      </section>
      {isPlay &&
      <PlayVideo isOpen={isPlay} videoId="V8ksSGxo6no"isCloseFunction={setPlay} videourl={props.videolink}/>
      }
    </React.Fragment>
  );
};
export default Customer;
